.contact-form{
    padding: 0 3rem 0 3rem;
    display: flex;
    height: 80vh;
    margin-top: 4rem;
      /* scroll */
  padding-top: 3rem;
}

.c-right{
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
}

.c-right>form
{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    
}

.c-right .user{
    width: 20rem;
    height: 2rem;
    padding: 0.3em;
    outline: none;
    border: 2px solid var(--orange);
    border-radius: 8px;
    font-size: 16px;   
}

textarea{
    height: 4rem!important;
}

.c-blur1{
    top: 1rem;
    left: 8rem;
}

@media screen and (max-width: 480px) {
    .contact-form{
        padding: 0;
        flex-direction: column;
        gap: 5rem;
        height: 40rem;
    }
    .c-right .user{
        width: 16rem;
    }

}   

/* Add this to your Contact.css or a separate CSS file */
.error-message {
    background: #f51a1a; /* Red background color */
    color: white; /* Text color */
    padding: 8px 16px; /* Padding */
    border-radius: 4px; /* Rounded corners */
    position: absolute;
    top: 100%;
    left: 0;
    transform: translateY(5px); /* Add a little gap between input and message */
    z-index: 1; /* Ensure it's above the input */
    font-size: 14px; /* Font size */
    opacity: 0.9; /* Slightly transparent */
    pointer-events: none; /* Allow interaction with the input underneath */
  }
  
  .user.error {
    border-color: #f51a1a; /* Border color for errored input */
  }
  